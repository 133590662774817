import { Input } from '@common/components/atoms/Input';
import { Typography } from '@common/components/foundations/Typography';
import { useFeaturesStore } from '@common/store/features';
import { useWarnings } from '@common/store/warnings';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Tooltip,
  TooltipProvider,
  TooltipContent,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { Icon } from '@common/components/foundations/icons';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useDocumentTitle } from '@mantine/hooks';
import { Text } from '@mantine/core';
import { z } from 'zod';

const isWithinRange = (val: number | undefined) => val === undefined || (val >= 30 && val <= 100);
const validationMessage = 'Please enter a value between 30% and 100%.';
const validationEmptyMessage = 'Please enter a value';

export const targetOccupancySchema = z.object({
  ...Object.fromEntries(
    Array.from({ length: 12 }, (_, i) => [
      i,
      z
        .number()
        .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
        .refine(isWithinRange, { message: validationMessage })
    ])
  ),
  ...Object.fromEntries(
    Array.from({ length: 12 }, (_, i) => [
      `sold_${i + 1}`,
      z
        .number()
        .int()
        .optional()
        .refine((val) => val === undefined || val >= 0, {
          message: 'Please enter a positive value'
        })
    ])
  ),
  target_occupancy: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
});

export const TargetOccupancy = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Target Occupancy'));
  const { createWarning } = useWarnings();
  const { setValue, control } = useFormContext();
  const { features } = useFeaturesStore();
  const { hotelDetails } = useHotelDetails();

  const months: { [key: number]: string } = {
    0: t('January'),
    1: t('February'),
    2: t('March'),
    3: t('April'),
    4: t('May'),
    5: t('June'),
    6: t('July'),
    7: t('August'),
    8: t('September'),
    9: t('October'),
    10: t('November'),
    11: t('December')
  };

  const isMonthlyGroupBookings = features?.includes(21);
  const isOccupancyStrategy = features?.includes(12);

  return (
    <div>
      <div>
        <div className="mb-6 mt-4 grid max-w-5xl grid-cols-1 items-start md:grid-cols-2 md:gap-20">
          <div className="mb-8 flex max-w-5xl flex-col gap-4">
            <Text size="lg">{t('Target Occupancy')}</Text>
            <Text>
              {t(
                "Set this at your expected occupancy level so the system knows what your occupancy targets are. RoomPriceGenie then adjusts prices to reach your target occupancy. Having low occupancy targets means we don't reduce prices so much in quiet times. Having high occupancy targets leads to lower prices. Going higher than 90% occupancy in any month can adversely affect revenue."
              )}
            </Text>
            {!isOccupancyStrategy ? (
              <Controller
                name="target_occupancy"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    type="number"
                    value={value}
                    label={t('Target Occupancy') as string}
                    placeholder={t('Please enter target occupancy') as string}
                    onChange={(e) => {
                      const targetValue = e.target.value;
                      if (Number(targetValue) > 90) {
                        createWarning({
                          message: t(
                            'We recommend NOT targeting over 90% occupancy as this means your pricing will be lower than it needs to be.'
                          ),
                          ignore: false,
                          ignoreLabel: t('Set to 90%') as string,
                          dismissLabel: `${t('Confirm')} ${targetValue}%`
                        })
                          .then(() => {
                            onChange(targetValue);
                          })
                          .catch(() => {
                            onChange(90);
                          });
                      } else {
                        onChange(targetValue);
                      }
                    }}
                    showClearButton={false}
                    error={!!error}
                    trailingAddon={
                      <div className="flex items-center gap-2">
                        <TooltipProvider delayDuration={75}>
                          <Tooltip>
                            <TooltipTrigger type="button">
                              <Icon.Help className="h-5 w-5 fill-grey" />
                            </TooltipTrigger>
                            <TooltipContent side="bottom" className="max-w-sm text-left">
                              {t(
                                `Sets the occupancy target used to calculate your health score and how many ${hotelDetails?.room_apartment_space_name.toLowerCase()}s you’re expected to have left. Most clients set it to 80%. We don’t recommend aiming at 100%, what could be easily achieved by selling your ${hotelDetails?.room_apartment_space_name.toLowerCase()}s for $1. Always ending up at 100% may indicate that you’re charging too little.`
                              )}
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                        <Typography variant="meta-2" className="font-semibold">
                          %
                        </Typography>
                      </div>
                    }
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          <Typography element="span" color="error" variant="meta-2">
                            {error.message}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
            ) : null}
          </div>
          <div>
            {isOccupancyStrategy ? (
              <div className="grid max-w-[500px] grid-cols-1 items-center gap-5 ">
                <div
                  className={clsx(
                    'grid gap-3',
                    isMonthlyGroupBookings ? 'grid-cols-3' : 'grid-cols-2'
                  )}
                >
                  <Typography variant="paragraph-3" className="font-semibold" color="darkGrey">
                    {t('Month')}
                  </Typography>
                  <Typography variant="paragraph-3" className="font-semibold" color="darkGrey">
                    {t('Target Occupancy')}
                  </Typography>
                  {isMonthlyGroupBookings ? (
                    <Typography variant="paragraph-3" className="font-semibold" color="darkGrey">
                      {t('Room Nights Sold to Groups')}
                    </Typography>
                  ) : null}
                </div>
                {Object.keys(months).map((key) => {
                  const numKey = Number(key);
                  return (
                    <div
                      key={key}
                      className={clsx(
                        'grid items-center gap-3',
                        isMonthlyGroupBookings ? 'grid-cols-3' : 'grid-cols-2'
                      )}
                    >
                      <Typography>{months[numKey]}</Typography>
                      <div className="max-w-[164px]">
                        <Controller
                          name={`${numKey}` as never}
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                              name={key}
                              type="number"
                              value={value}
                              placeholder={months[numKey].slice(0, 3)}
                              onChange={(e) => {
                                const targetValue = e.target.value;
                                if (Number(targetValue) > 90) {
                                  createWarning({
                                    message: t(
                                      'We recommend NOT targeting over 90% occupancy as this means your pricing will be lower than it needs to be.'
                                    ),
                                    ignore: false,
                                    ignoreLabel: t('Set to 90%') as string,
                                    dismissLabel: `${t('Confirm')} ${targetValue}%`
                                  })
                                    .then(() => {
                                      onChange(targetValue);
                                    })
                                    .catch(() => {
                                      onChange(90);
                                    });
                                } else {
                                  onChange(targetValue);
                                }
                              }}
                              showClearButton={false}
                              error={!!error}
                              trailingAddon={<>%</>}
                              helperMessage={
                                error && (
                                  <div className="flex items-center gap-2 text-error">
                                    <Typography element="span" color="error" variant="meta-2">
                                      {error.message}
                                    </Typography>
                                  </div>
                                )
                              }
                            />
                          )}
                        />
                      </div>
                      {isMonthlyGroupBookings ? (
                        <div className="max-w-[164px]">
                          <Controller
                            name={`sold_${numKey + 1}` as never}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Input
                                name={key}
                                type="number"
                                value={value}
                                placeholder={months[numKey].slice(0, 3)}
                                onChange={(e) => {
                                  const parsedValue = parseInt(e.target.value);
                                  if (isNaN(parsedValue)) {
                                    onChange(10);
                                  } else {
                                    onChange(parsedValue);
                                  }
                                }}
                                onClear={() => setValue(`sold_${numKey + 1}` as never, 1 as never)}
                                error={!!error}
                                helperMessage={
                                  error && (
                                    <div className="flex items-center gap-2 text-error">
                                      <Typography element="span" color="error" variant="meta-2">
                                        {error.message}
                                      </Typography>
                                    </div>
                                  )
                                }
                              />
                            )}
                          />
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
