import { useDocumentTitle } from '@mantine/hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Group, NumberInput, rem, Stack, Text } from '@mantine/core';
import { z } from 'zod';
import { Flags } from '@common/constants/flags';
import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

export const shoulderNightDiscountsSchema = z.object({
  apply_shoulder_night_discounts: z.boolean(),
  one_night: z
    .number()
    .min(0, 'Discount must be 0 or greater')
    .max(50, 'Discount cannot exceed 50%')
    .optional()
    .nullable(),
  two_nights: z
    .number()
    .min(0, 'Discount must be 0 or greater')
    .max(50, 'Discount cannot exceed 50%')
    .optional()
    .nullable()
});

export type ShoulderNightDiscountsForm = z.infer<typeof shoulderNightDiscountsSchema>;

export const ShoulderNightDiscounts = () => {
  const flags = useFlags();
  const { hotelDetails } = useHotelDetails();
  const navigate = useNavigate();

  useEffect(() => {
    if (hotelDetails && !flags[Flags.IsShoulderNightDiscountEnabled])
      navigate(`/client/${hotelDetails.id}/calendar`);
  }, [flags, hotelDetails, navigate]);

  const { t } = useTranslation();

  const { control, watch, setValue } = useFormContext<ShoulderNightDiscountsForm>();
  const applyShoulderNightDiscounts = watch('apply_shoulder_night_discounts');

  useDocumentTitle(t('Shoulder Night Discounts'));

  return (
    <Group align="flex-start" gap="lg">
      <Stack maw={rem(600)}>
        <Text size="lg">{t('Shoulder Night Discounts')}</Text>
        <Text>
          {t(`If you are fully booked 1 or 2 nights before or after a date then your guests have more
          limited options to stay (e.g. maybe a stay of only 2 nights is possible). This reduces the
          demand for that date.`)}
        </Text>
        <Text>
          {t(`You can counter this by adding a discount to these "Shoulder Nights". If the night before or
          after, or the 2 nights before or 2 nights after, a particular date then we will apply the
          discounts here. These are cumulative, so if both the night before and after are booked then
          we will add a discount double to the "+ / - 1 night" value.`)}
        </Text>
      </Stack>

      <Stack gap="md" w={rem(400)}>
        <Controller
          name="apply_shoulder_night_discounts"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value ?? false}
              onChange={(e) => {
                const newValue = e.currentTarget.checked;
                onChange(newValue);
                if (!newValue) {
                  setValue('one_night', 0);
                  setValue('two_nights', 0);
                }
              }}
              label={t('Apply shoulder night discounts')}
            />
          )}
        />

        <Box>
          <Text size="sm">{t('Percentage Discount')}</Text>
          <Stack gap="lg" w="100%">
            <Controller
              name="one_night"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumberInput
                  {...field}
                  value={field.value ?? undefined}
                  onChange={(val) => field.onChange(val === '' ? undefined : val)}
                  label={t('+/- 1 night')}
                  disabled={!applyShoulderNightDiscounts}
                  rightSection="%"
                  placeholder="10"
                  error={error?.message}
                  mb={rem(4)}
                  allowDecimal={false}
                  hideControls
                  min={0}
                />
              )}
            />
            <Controller
              name="two_nights"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumberInput
                  {...field}
                  value={field.value ?? undefined}
                  onChange={(val) => field.onChange(val === '' ? undefined : val)}
                  label={t('+/- 2 night')}
                  disabled={!applyShoulderNightDiscounts}
                  rightSection="%"
                  placeholder="10"
                  error={error?.message}
                  mb={rem(4)}
                  allowDecimal={false}
                  hideControls
                  min={0}
                />
              )}
            />
          </Stack>
        </Box>
      </Stack>
    </Group>
  );
};
