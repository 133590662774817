import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { RadioGroup } from '@headlessui/react';
import { useDocumentTitle } from '@mantine/hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';
import { z } from 'zod';

export const medianLeadTimeSchema = z.object({
  medianLeadTime: z.number().or(z.string().transform(Number))
});

export const MedianBookingWindow = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Median Booking Window'));
  const { control, watch, setValue } = useFormContext();

  const medianLeadTime = watch().medianLeadTime;

  const MEDIAN_LEAD_TIME = [
    {
      title: t('Short'),
      label: t('Less than 14 days, typical for city hotels'),
      value: 14,
      custom: false
    },
    {
      title: t('Standard'),
      label: t('15-30 days, this is the default setting'),
      value: 21,
      custom: false
    },
    {
      title: t('Long'),
      label: t(
        'your average booking is made more than 30 days before arrival, this may be the case for ski resorts or other destination hotels'
      ),
      value: 30,
      custom: false
    },
    {
      title: t('Custom'),
      label: t('Please contact support'),
      value: 0,
      custom: true
    }
  ];

  return (
    <div>
      <div className="mb-6 mt-4 grid grid-cols-1 items-start gap-20 md:grid-cols-12">
        <div className="col-span-5 mb-8 flex  flex-col gap-4">
          <Text size="lg">{t('Median Booking Window')}</Text>
          <Text>{t('50% of your bookings are made within how many days prior to check in?')}</Text>
        </div>
        <div className="col-span-5 md:col-span-7">
          <div>
            <div className="w-full">
              <div className="mx-auto w-full md:max-w-3xl">
                <Controller
                  name="medianLeadTime"
                  control={control}
                  // defaultValue={14}
                  render={({ field }) => (
                    <RadioGroup value={field.value} onChange={field.onChange}>
                      <RadioGroup.Label className="sr-only">Median Lead Time</RadioGroup.Label>
                      <div className="space-y-4">
                        {MEDIAN_LEAD_TIME.map((median) => (
                          <RadioGroup.Option
                            key={median.title}
                            value={median.value}
                            className={({ active, checked }) =>
                              `
                            ${active ? 'ring-1 ring-indigo-reduced' : ''}
                            ${checked ? 'border border-indigo bg-white' : 'bg-white'}
                            focus:ring-3 relative flex h-[100px] cursor-pointer rounded-lg px-5 py-5 focus:ring-indigo-reduced`
                            }
                          >
                            {({ checked }) => (
                              <>
                                <div className="flex w-full items-center justify-between">
                                  <div className="flex items-center">
                                    <div className="max-w-[600px] text-sm ">
                                      <RadioGroup.Label as="span" className="mb-2">
                                        <Typography
                                          variant="paragraph-1"
                                          element="p"
                                          color="darkGrey"
                                        >
                                          {median.title}
                                        </Typography>
                                      </RadioGroup.Label>
                                      <RadioGroup.Description as="span" className="font-light">
                                        <Typography
                                          variant="paragraph-3"
                                          element="span"
                                          color="copyTextGrey"
                                        >
                                          {median.label}
                                        </Typography>
                                      </RadioGroup.Description>
                                    </div>
                                  </div>
                                  {checked && (
                                    <div className="shrink-0 text-indigo">
                                      <Icon.CheckList className="h-6 w-6" />
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  )}
                />
              </div>
            </div>
            {medianLeadTime !== 14 && medianLeadTime !== 21 && medianLeadTime !== 30 ? (
              <div className="my-4">
                <Controller
                  name={'medianLeadTime'}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <Input
                      name={name}
                      type="number"
                      showClearButton={false}
                      value={parseInt(value)}
                      label={`${t('Lead Time Median')} *`}
                      placeholder={`${t('Please enter lead time median')}`}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (isNaN(value)) {
                          onChange(0);
                        } else {
                          onChange(value);
                        }
                      }}
                      onClear={() => setValue('medianLeadTime', 0)}
                      error={!!error}
                      trailingAddon={<div className="pl-2">{t('days')}</div>}
                      helperMessage={
                        error && (
                          <div className="flex items-center gap-2 text-error">
                            <Icon.WarningOutline className="w-4" />
                            <Typography element="span" color="error" variant="meta-2">
                              {error?.message?.toString()}
                            </Typography>
                          </div>
                        )
                      }
                    />
                  )}
                />
              </div>
            ) : null}
            <div className="my-4">
              <Typography variant="paragraph-3" element="p" className="font-light">
                {t(
                  'Note: The lead time is used to set expectations about when bookings are made. This affects the colours in the occupancy view and the pricing. For example, if lead time is set to Long, and you do not see the expected demand, we will suggest reducing prices earlier.'
                )}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
