import { Button } from '@common/components/atoms/Button';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useHotelStore } from '@common/store/auth';
import { Feature, useFeaturesStore } from '@common/store/features';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import * as z from 'zod';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelUpdate } from '@pages/Client/hooks/useHotelUpdate';
import { shoulderNightDiscountsSchema } from './pages/ShoulderNightDiscounts/ShoulderNightDiscounts';
import { targetOccupancySchema } from './pages/TargetOccupancy/TargetOccupancy';
import { aggressivenessSchema } from './pages/Aggressiveness/Aggressiveness';
import { medianLeadTimeSchema } from './pages/MedianBookingWindow/MedianBookingWindow';
import { closeOutSalesSchema } from './pages/CloseOutSales/CloseOutSales';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flags } from '@common/constants/flags';
import { useViewStore } from '@common/store/view';

const mergedSchema = z.object({
  ...targetOccupancySchema.shape,
  ...medianLeadTimeSchema.shape,
  ...aggressivenessSchema.shape,
  ...closeOutSalesSchema.shape,
  ...shoulderNightDiscountsSchema.shape
});

type FormValues =
  | z.infer<typeof targetOccupancySchema>
  | z.infer<typeof medianLeadTimeSchema>
  | z.infer<typeof aggressivenessSchema>
  | z.infer<typeof closeOutSalesSchema>
  | z.infer<typeof shoulderNightDiscountsSchema>
  | z.infer<typeof mergedSchema>;

export const OccupancyStrategy = () => {
  const { t } = useTranslation();
  const { hotelAuthToken } = useHotelStore();
  const location = useLocation();
  const { features } = useFeaturesStore();
  const flags = useFlags();
  const { view } = useViewStore();

  const isMedianBookingWindowPage = location.pathname.includes('median-booking-window');
  const isAggressivenessPage = location.pathname.includes('aggressiveness');
  const isCloseOutSalesPage = location.pathname.includes('close-out-sales');
  const isMinStayRulesPage = location.pathname.includes('min-stay-rules');
  const isShoulderNightDiscountsPage = location.pathname.includes('shoulder-night-discounts');
  const [isSuccessRef, setIsSuccessRef] = useState<{ current: boolean }>({ current: false });

  const isHasAccessAggressiveness = features?.includes(Feature.OccupancyStrategy);
  const isShoulderNightDiscountsEnabled = flags[Flags.IsShoulderNightDiscountEnabled] ?? false;

  const {
    isLoading: isSavePricingLoading,
    savePricingSettings,
    isSuccess: isSaveSuccess
  } = useUpdatePricingSettings();

  const { pricingSettingsQuery } = useRoomPrices();
  const { data: pricingSettings } = pricingSettingsQuery;

  const { hotelDetails, query: hotelDetailQuery } = useHotelDetails();
  const {
    updateHotel,
    isLoading: hotelUpdateLoading,
    isSuccess: isHotelUpdateSuccess
  } = useHotelUpdate();

  const getSchema = () => {
    if (isMedianBookingWindowPage) return medianLeadTimeSchema;
    if (isAggressivenessPage) return aggressivenessSchema;
    if (isCloseOutSalesPage) return closeOutSalesSchema;
    if (isShoulderNightDiscountsPage) return shoulderNightDiscountsSchema;
    return targetOccupancySchema;
  };

  const methods = useForm<FormValues>({
    resolver: zodResolver(getSchema()),
    mode: 'onChange'
  });

  useEffect(() => {
    const defaultValues = {
      medianBookingWindow: {
        medianLeadTime: pricingSettings?.rpg_arguments.median_lead_time
      },
      aggressiveness: {
        PELL_weekday: pricingSettings?.rpg_arguments.PELL_weekday,
        PELL_weekend: pricingSettings?.rpg_arguments.PELL_weekend,
        do_not_reduce_last_day: pricingSettings?.rpg_arguments.do_not_reduce_last_day
      },
      closeOutSales: {
        close_out_days: hotelDetails?.close_out_days,
        close_out_hour: hotelDetails?.close_out_hour
      },
      shoulderNightDiscounts: {
        apply_shoulder_night_discounts:
          Number(pricingSettings?.shoulder_night_discounts?.one_night) > 0 ||
          Number(pricingSettings?.shoulder_night_discounts?.two_nights) > 0
            ? true
            : false,
        one_night: pricingSettings?.shoulder_night_discounts?.one_night ?? 0,
        two_nights: pricingSettings?.shoulder_night_discounts?.two_nights ?? 0
      },
      targetOccupancy: {
        monthly: pricingSettings?.monthly_target_occupancy?.reduce(
          (acc, val, index) => ({ ...acc, [index]: val }),
          {}
        ),
        roomNightSold:
          pricingSettings?.rooms_sold_to_groups &&
          Object.entries(pricingSettings.rooms_sold_to_groups).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [`sold_${key}`]: value
            }),
            {}
          ),
        target: pricingSettings?.hotel.target_occupancy
      }
    };

    const setValues = () => {
      if (isMedianBookingWindowPage && defaultValues.medianBookingWindow.medianLeadTime) {
        methods.setValue('medianLeadTime', defaultValues.medianBookingWindow.medianLeadTime);
        return;
      }

      if (isAggressivenessPage) {
        const { PELL_weekday, PELL_weekend, do_not_reduce_last_day } = defaultValues.aggressiveness;
        methods.setValue('PELL_weekday', PELL_weekday as number);
        methods.setValue('PELL_weekend', PELL_weekend as number);
        methods.setValue('do_not_reduce_last_day', do_not_reduce_last_day as boolean);
        return;
      }

      if (isCloseOutSalesPage) {
        const { close_out_days, close_out_hour } = defaultValues.closeOutSales;
        methods.setValue('close_out_days', close_out_days as number);
        methods.setValue('close_out_hour', close_out_hour as number);
        return;
      }

      if (isShoulderNightDiscountsPage) {
        const { apply_shoulder_night_discounts, one_night, two_nights } =
          defaultValues.shoulderNightDiscounts;
        const shoulderNightDiscountsDay1 = one_night as unknown as number;
        const shoulderNightDiscountsDay2 = two_nights as unknown as number;
        const applyShoulderNightDiscounts =
          Boolean(shoulderNightDiscountsDay1) ||
          Boolean(shoulderNightDiscountsDay2) ||
          (apply_shoulder_night_discounts as unknown as boolean);

        methods.setValue('apply_shoulder_night_discounts', applyShoulderNightDiscounts);
        methods.setValue('one_night', shoulderNightDiscountsDay1);
        methods.setValue('two_nights', shoulderNightDiscountsDay2);
        return;
      }

      // Default case - target occupancy
      const { monthly, roomNightSold, target } = defaultValues.targetOccupancy;

      if (roomNightSold) {
        Object.entries(roomNightSold).forEach(([key, value]) => {
          methods.setValue(key as never, value as never);
        });
      }

      if (monthly) {
        Object.entries(monthly).forEach(([key, value]) => {
          methods.setValue(key as never, value as never);
        });
      }

      methods.setValue('target_occupancy', target as number);
    };

    setValues();
  }, [
    methods.setValue,
    isMedianBookingWindowPage,
    isAggressivenessPage,
    isCloseOutSalesPage,
    isShoulderNightDiscountsPage,
    hotelDetails,
    pricingSettings?.monthly_target_occupancy,
    pricingSettings?.rpg_arguments.median_lead_time,
    pricingSettings?.rpg_arguments.PELL_weekday,
    pricingSettings?.rpg_arguments.PELL_weekend,
    pricingSettings?.rpg_arguments.do_not_reduce_last_day,
    pricingSettings?.shoulder_night_discounts?.one_night,
    pricingSettings?.shoulder_night_discounts?.two_nights
  ]);

  const onSubmit = async (data: any) => {
    if (isShoulderNightDiscountsPage) {
      const validationResult = shoulderNightDiscountsSchema.safeParse(data);
      if (!validationResult.success) {
        console.error('Validation failed:', validationResult.error);
        return;
      }
    }

    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();

    const getUpdatedSettings = () => {
      if (isMedianBookingWindowPage) {
        return {
          ...latestPricingSettings,
          rpg_arguments: {
            ...latestPricingSettings?.rpg_arguments,
            median_lead_time: data.medianLeadTime
          }
        };
      }

      if (isAggressivenessPage) {
        return {
          ...latestPricingSettings,
          rpg_arguments: {
            ...latestPricingSettings?.rpg_arguments,
            PELL_weekday: data.PELL_weekday,
            PELL_weekend: data.PELL_weekend,
            do_not_reduce_last_day: data.do_not_reduce_last_day
          }
        };
      }

      if (isCloseOutSalesPage) {
        return {
          close_out_days: data.close_out_days,
          close_out_hour: data.close_out_hour,
          token: hotelAuthToken
        };
      }

      if (isShoulderNightDiscountsPage) {
        return {
          ...latestPricingSettings,
          shoulder_night_discounts: {
            one_night: data.one_night,
            two_nights: data.two_nights
          }
        };
      }

      // Target Occupancy page
      const monthlyTargetOccupancy = Object.values(data).slice(0, 12);
      const roomsSoldToGroups = Object.values(data).slice(12);
      const roomsSoldToGroupsObject = roomsSoldToGroups.reduce<{ [key: number]: number }>(
        (acc, val, index) => {
          acc[index + 1] = Number(val);
          return acc;
        },
        {}
      );

      return {
        ...latestPricingSettings,
        hotel: {
          ...latestPricingSettings?.hotel,
          target_occupancy: data.target_occupancy
        },
        monthly_target_occupancy: monthlyTargetOccupancy,
        rooms_sold_to_groups: roomsSoldToGroupsObject
      };
    };

    const settings = getUpdatedSettings();

    if (isCloseOutSalesPage) {
      await updateHotel(settings);
      await hotelDetailQuery.refetch();
    } else {
      await savePricingSettings(JSON.stringify(settings));
    }
  };

  const subMenuLink = [
    {
      id: 'target-occupancy',
      label: t('Target Occupancy'),
      to: 'target-occupancy'
    },
    {
      id: 'median-booking-window',
      label: t('Median Booking Window'),
      to: 'median-booking-window'
    },
    ...(isHasAccessAggressiveness || view === 'admin'
      ? [
          {
            id: 'aggressiveness',
            label: t('Aggressiveness'),
            to: 'aggressiveness'
          }
        ]
      : []),
    {
      id: 'close-out-sales',
      label: t('Close Out Sales (Last Day)'),
      to: 'close-out-sales'
    },
    ...(isShoulderNightDiscountsEnabled
      ? [
          {
            id: 'shoulder-night-discounts',
            label: t('Shoulder Night Discounts'),
            to: 'shoulder-night-discounts'
          }
        ]
      : [])
  ];

  useEffect(() => {
    setIsSuccessRef({ current: isSaveSuccess || isHotelUpdateSuccess });
    if (isSaveSuccess) {
      setTimeout(() => {
        setIsSuccessRef({ current: false });
      }, 2000);
    }
  }, [isSaveSuccess, isHotelUpdateSuccess]);

  return (
    <FormProvider {...methods}>
      <Page
        header={
          <Header
            title={`${t('Occupancy Strategy')}`}
            tabs={subMenuLink}
            actions={
              isMinStayRulesPage ? null : (
                <Button
                  disabled={
                    isSavePricingLoading ||
                    hotelUpdateLoading ||
                    (!methods.formState.isDirty && !methods.formState.isValid)
                  }
                  isSuccess={isSuccessRef.current}
                  isLoading={isSavePricingLoading}
                  intent="primary"
                  type="submit"
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  {t('Save')}
                </Button>
              )
            }
          />
        }
      >
        <Outlet />
      </Page>
    </FormProvider>
  );
};
