import { Table } from '@common/components/molecules/Table';
import { useQuotationsListStore } from '@common/store/group-displacement-list-store';
import { Badge, Button, Flex, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import DetailQuotationModal from '@pages/Client/PricingStrategy/GroupDisplacement/components/DetailQuotationModal';
import QuotationModal from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationModal';
import { QuotationsFiltersPopover } from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationsTable/QuotationsFilterPopover';
import { QuotationsListSearch } from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationsTable/QuotationsListSearch';
import {
  QuotationStatusOption,
  quotationStatusOptions
} from '@pages/Client/PricingStrategy/GroupDisplacement/schema/quotation';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useState } from 'react';
import { CellContext, ColumnDef, SortingState, Updater } from '@tanstack/react-table';
import { quotationOrderingLookup } from '@pages/Client/PricingStrategy/GroupDisplacement/schema/quotations-list';
import { isNil, map } from 'lodash-es';
import { useQuotationsList } from '@pages/Client/PricingStrategy/GroupDisplacement/hooks/useGroupDisplacement';
import { QuotationsListPagination } from '@pages/Client/PricingStrategy/GroupDisplacement/types';
import { ActiveFiltersList } from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationsTable/ActiveFiltersList';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { useResourceCenter } from '@common/hooks/useResourceCenter';

const QuotationsTable = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [detailOpened, { open: detailOpen, close: detailClose }] = useDisclosure(false);
  const [selectedQuote, setSelectedQuote] = useState<number | string | null>(null);

  const { data: groupQuotations, isLoading } = useQuotationsList();
  const { setParams, sorting, setSorting } = useQuotationsListStore();
  const { trackEvent } = useEventTracking();

  useResourceCenter({ shouldHideResourceCenter: opened || detailOpened });

  const currentCount = groupQuotations?.current;

  function handleSortingChange(updater: Updater<SortingState>) {
    const newSorting = typeof updater === 'function' ? updater(sorting) : updater;
    if (newSorting.length === 0) return;

    const columnExists = sorting.some((s) => s.id === newSorting[0].id);
    if (columnExists) newSorting[0].desc = !newSorting[0].desc;

    const ordering = newSorting[0]?.desc
      ? `-${quotationOrderingLookup[newSorting[0]?.id]}`
      : quotationOrderingLookup[newSorting[0]?.id];

    setParams({ ordering });
    setSorting(newSorting);
  }

  function handleNext() {
    if (isNil(currentCount)) return;
    setParams({ page: currentCount + 1 });
  }

  function handlePrevious() {
    if (isNil(currentCount) || currentCount === 0) return;
    setParams({ page: currentCount - 1 });
  }

  const handleDetailClose = () => {
    setSelectedQuote(null);
    detailClose();
  };

  const columns: ColumnDef<any>[] = [
    { header: 'ID', accessorKey: 'id' },
    { header: 'Group Name', accessorKey: 'name' },
    {
      header: 'Start Date',
      accessorKey: 'start_date',
      cell: (row: CellContext<any, unknown>) => (
        <Text miw="100" size="sm">
          {row.renderValue() as string}
        </Text>
      )
    },
    {
      header: 'Number of Rooms',
      accessorKey: 'number_of_rooms',
      meta: {
        cellAlignment: 'center'
      }
    },
    {
      header: 'Nights',
      accessorKey: 'length_of_stay',
      meta: {
        cellAlignment: 'center'
      }
    },
    {
      header: 'Average Per Room Per Night',
      accessorKey: 'average_price',
      meta: {
        cellAlignment: 'right'
      }
    },
    {
      header: 'Total Quote',
      accessorKey: 'total_quote',
      meta: {
        cellAlignment: 'right'
      }
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (row: CellContext<any, unknown>) => (
        <Flex align="center" justify="center" w="80">
          <Badge
            variant="contained"
            bg={quotationStatusOptions[row.getValue() as QuotationStatusOption]?.color}
          >
            {t(quotationStatusOptions[row.getValue() as QuotationStatusOption]?.label)}
          </Badge>
        </Flex>
      )
    },
    {
      header: 'Last updated',
      accessorKey: 'last_updated_at'
    }
  ];

  const ROWS_PER_PAGE = 20;

  const startIndex = groupQuotations?.current
    ? (groupQuotations?.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    groupQuotations?.current && groupQuotations?.results
      ? startIndex + (groupQuotations?.results?.length || 0) - 1
      : 0;
  function pickQuotationValues(quotations: QuotationsListPagination | undefined) {
    const output: {
      id: number;
      name: string;
      created_at: string;
      last_updated_at: string;
      number_of_rooms: number;
      start_date: string;
      length_of_stay: number;
      total_quote: string;
      average_price: string;
      status: 'APPROVED' | 'REJECTED' | 'IN_REVIEW';
      s3_path: string;
    }[] = [];
    if (isNil(quotations)) return output;
    map(quotations.results, (quotation) => {
      const update = {
        id: quotation.id,
        name: quotation.name || '-',
        created_at: dayjs(quotation.created_at).format('YYYY-MM-DD') || '-',
        last_updated_at: dayjs(quotation.last_updated_at).format('YYYY-MM-DD HH:mm') || '-',
        number_of_rooms: quotation.number_of_rooms,
        start_date: dayjs(quotation.start_date).format('YYYY-MM-DD') || '-',
        length_of_stay: quotation.length_of_stay,
        total_quote: CurrencyFormatter.format(quotation.total_quote),
        average_price: CurrencyFormatter.format(quotation.average_price),
        status: quotation.status,
        s3_path: quotation.s3_path
      };
      output.push(update);
    });
    return output;
  }

  const header = (
    <Flex w="100%" align="center" direction="row" justify="space-between">
      <Text size="lg" c="dark">
        {t('Quotations')}
      </Text>
    </Flex>
  );
  return (
    <>
      <Table
        isFetching={isLoading}
        skeletonCount={20}
        headerComponent={header}
        columns={columns}
        data={pickQuotationValues(groupQuotations)}
        next={handleNext}
        isNext={!!groupQuotations?.next}
        previous={handlePrevious}
        isPrevious={!!groupQuotations?.previous}
        count={`${startIndex}-${endIndex}`}
        totalCount={groupQuotations?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
        manualSorting={true}
        isStriped
        isRowClickable
        isHover
        onSortingChange={handleSortingChange}
        onClickRow={(row) => {
          setSelectedQuote(row?.row?.original?.id);
          detailOpen();
        }}
        actionComponents={
          <Group gap="md">
            <ActiveFiltersList />
            <QuotationsListSearch />
            <QuotationsFiltersPopover />
            <Button
              onClick={() => {
                open();
                trackEvent('CalculateNewQuotationOpened');
              }}
            >
              {t('Calculate New Quotation')}
            </Button>
          </Group>
        }
      />
      <QuotationModal isOpen={opened} onClose={close} />
      <DetailQuotationModal isOpen={detailOpened} onClose={handleDetailClose} id={selectedQuote} />
    </>
  );
};

export default QuotationsTable;
