export enum Flags {
  MinStayV2 = 'min-stay-v-2',
  PmsConfigFromBackend = 'pms-config-from-backend',
  TenPercentFixedPricesThreshold = 'ten-percent-fixed-prices-threshold',
  AIChatBot = 'ai-chat-bot',
  ReportingDashboardV2 = 'reporting-dashboard-v-2',
  HomeScreen = 'home-screen',
  MinStayMultipleRatePlans = 'minstay-multiple-rate-plans',
  DerivedRatesV2 = 'new-rate-plan-derivation',
  AnalyticsCompareTo = 'analytics-compare-to',
  Show12MonthsBudgetBanner = 'show-12-months-budget-banner',
  ForeCastingV2 = 'forecasting-v-2',
  PricingCheeseLayers = 'is-pricing-cheeselayers-enabled',
  GroupDisplacementRollout = 'group-displacement-rollout',
  HideOverridePriceSubscription = 'hide-override-price-subscription-page',
  IsShoulderNightDiscountEnabled = 'is-shoulder-night-discount-enabled'
}
